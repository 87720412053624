<template>
  <b-dropdown-item
    @click="removeFromSegment"
  >
    {{ $t('TABLES.PROJECT_USER_NAVS.REMOVE_SEGMENT') }}
  </b-dropdown-item>
</template>

<script>
import { mapGetters } from "vuex";
import { BULK_UNASSIGN_USERS } from "@/core/services/store/project/project_segments.module";

export default {
  name: "RemoveFromSegment",

  props: {
    checkedUsers: { type: Array, required: true },
    segmentChoices: { type: Object, required: true }
  },

  methods: {
    removeFromSegment: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.REMOVE_SEGMENT_BULK.TITLE")}`,
        text: `${this.$t("SWEETALERTS.REMOVE_SEGMENT_BULK.MESSAGE")}`,
        input: 'select',
        inputOptions: this.segmentChoices,
        inputPlaceholder: `${this.$t("SETUP.NAV.SEGMENTS")}`,
        showCancelButton: true,
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`,
        inputValidator: (value) => {
          let payload = {
            projectId: this.projectInfo.id,
            segmentId: value,
            users: this.checkedUsers
          };
          this.$store
            .dispatch(BULK_UNASSIGN_USERS, payload)
            .then(() => {
              this.alertSuccess();
              this.$emit('users_changed');
            })
            .catch(() => {
              this.alertFailure();
            });
        }
      })
    },
    alertSuccess: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.REMOVE_SEGMENT_BULK_SUCCESS.TITLE")}`,
        text: `${this.$t("SWEETALERTS.REMOVE_SEGMENT_BULK_SUCCESS.MESSAGE")}`,
        icon: 'success',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    },
    alertFailure: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.REMOVE_SEGMENT_BULK_FAIL.TITLE")}`,
        text: `${this.$t("SWEETALERTS.REMOVE_SEGMENT_BULK_FAIL.MESSAGE")}`,
        icon: 'error',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),
  }
};
</script>
