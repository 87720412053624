<template>
  <b-dropdown-item
    @click="suspendUsers"
  >
    {{ $t('TABLES.PROJECT_USER_NAVS.BLOCK') }}
  </b-dropdown-item>
</template>

<script>
import { mapGetters } from "vuex";
import { TOGGLE_SUSPENSION_STATE_BULK } from "@/core/services/store/core/publishing.module";

export default {
  name: "BlockUsers",

  props: {
    checkedUsers: { type: Array, required: true }
  },

  methods: {
    suspendUsers: function() {
      let basePayload = {
        projectId: this.projectInfo.id,
        users: this.checkedUsers,
        suspension: 1
      };

      if (this.currentUser.relation_to_project == "super_admin") {
        this.$swal.fire({
          title: `${this.$t("SWEETALERTS.BLOCK_BULK_CHALLENGE.TITLE")}`,
          text: `${this.$t("SWEETALERTS.BLOCK_BULK_CHALLENGE.MESSAGE")}`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `${this.$t("GENERAL.STATUS.SYSTEM_BLOCKED")}`,
          confirmButtonColor: "#181C32",
          denyButtonText: `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.PROJECT_BLOCKED')}`,
          denyButtonColor: "#e40049",
          customClass: {
            denyButton: 'block',
            confirmButton: 'block'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            basePayload.type = "system";
            this.actionSuspension(basePayload);
          } else if (result.isDenied) {
            basePayload.type = "project";
            this.actionSuspension(basePayload);
          }
        })
      } else {
        basePayload.type = "project";
        this.actionSuspension(basePayload);
      }
    },
    actionSuspension: function(payload) {
      this.$store
        .dispatch(TOGGLE_SUSPENSION_STATE_BULK, payload)
        .then(() => {
          this.alertSuccess();
          this.$emit('users_changed');
        })
        .catch(() => {
          this.alertFailure();
        });
    },
    alertSuccess: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.BLOCK_BULK_SUCCESS.TITLE")}`,
        text: `${this.$t("SWEETALERTS.BLOCK_BULK_SUCCESS.MESSAGE")}`,
        icon: 'success',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    },
    alertFailure: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.BLOCK_BULK_FAIL.TITLE")}`,
        text: `${this.$t("SWEETALERTS.BLOCK_BULK_FAIL.MESSAGE")}`,
        icon: 'error',
        confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
      })
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo"
    ]),
  }
};
</script>
